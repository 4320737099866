/* General Styles */
body {
  font-family: Arial, sans-serif;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 20px; 
}

.app-content {
  max-width: 100%;
  width: 100%;
  padding: 0 20px;
}

.container-fluid {
  min-height: 0;
  flex: 1;
  overflow-y: auto;
}

.italic-text {
  font-style: italic;
}

/* Navbar */

.full-width-header {
  position: relative;
  display: flex;
  justify-content: center;
}

.navbar-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
}

.demo-text-container {
  text-align: center;
  flex: 1;
}

.logout-btn {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.logout-icon {
  width: 24px;
  height: 24px;
  filter: invert(1); /* This will make the icon white */
}

.btn-link:hover .logout-icon {
  opacity: 0.8;
}

.bold-text {
  font-weight: bold;
  font-size: 20px;
}



/* Header Styles */
.full-width-header {
  width: 100%;
  background-color: #343a40;
  padding: 10px 0;
}

.full-width-header .navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  color: white;
}

.demo-text-container {
  text-align: center;
  padding: 0.5rem;
}

.user-info-container {
  margin-right: 1rem;
  color: #fff;
}

.logo {
  height: 45px;
}

/* Footer Styles */
.footer {
  background-color: #343a40;
  padding: 10px 0;
  color: white;
}

/* Modal Styles */
.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  max-width: 500px;
  margin: 1.75rem auto;
}

.modal-header {
  background-color: #343a40;
  color: #fff;
}

.modal-footer {
  justify-content: flex-start;
}

.modal-content-center {
  position: relative;
  max-width: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Adjust spacing between buttons */
.card-body .d-flex > * {
  margin: 5px !important;
}

/* Card and close button styles */
.card {
  position: relative;
}

.card-title-with-close {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-title {
  margin-bottom: 0;
  flex-grow: 1;
  font-size: 1rem;
  text-align: left;
  font-weight: 400;
}

.close-icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.25rem;
  background: none;
  border: none;
  font-size: 0.9rem;
  line-height: 1;
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
}

.close-icon:hover {
  opacity: 1;
}

.card,
.card-body {
  border-radius: 0 !important;
}


/* Add Stream CSS */

.placeholder-wrapper {
  position: relative;
}

.custom-placeholder-text {
  position: absolute;
  left: 12px;  
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d !important;
  pointer-events: none;
}

.custom-input::placeholder {
  color: #6c757d !important;
  opacity: 1 !important;
}

/* For Internet Explorer */
.custom-input:-ms-input-placeholder {
  color: #6c757d !important;
}

/* For Microsoft Edge */
.custom-input::-ms-input-placeholder {
  color: #6c757d !important;
}

.custom-input:not(:placeholder-shown) + .custom-placeholder-text {
  display: none;
}


/* vjs settings */

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
}

.video-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  border-radius: 6px;
  overflow: hidden;
  background-color: black;
}

.video-js.vjs-fullscreen {
  max-height: 100vh;
}

.single-player-wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
}

@media (min-width: 2160px) {
  .single-player-wrapper {
    max-width: 1280px;
  }
}

.multiview-container {
  display: grid;
  grid-template-columns: repeat(var(--pips-per-row), 1fr);
  gap: .5rem;
  width: 100%;
  margin: 0 auto;
}





/* Individual video wrapper in multiview */
.multiview-video-wrapper {
  width: 100%;
}

/* Single player in multiview mode */
.multiview-container:only-child .multiview-video-wrapper {
  max-width: 960px;
  margin: 0 auto;
}


/* Video.js Custom Styles */
.vjs-live-ui .vjs-progress-control,
.vjs-fullscreen-control,
.vjs-picture-in-picture-control,
.vjs-progress-control,
.vjs-play-control {
  display: none !important;
}

.vjs-mute-control {
  display: flex !important;
}

.vjs-volume-panel {
  order: 1; 
}

.vjs-control-bar {
  color: #fff;
  font-size: 14px;
  border-radius: 0 0 5px 5px;
}

.video-js:hover .vjs-control-bar,
.video-js:not(:hover) .vjs-control-bar {
  background-color: transparent; 
}

.dropdown-container {
  position: relative;
}

.dropdown-menu {
  position: fixed !important;
  max-height: 500px;
  min-height: 50px;
  overflow-y: auto;
  width: 300px; /* Adjust as needed */
  z-index: 1000;
}

/* Icon Styles */
.icon {
  width: 16px;
  height: 16px;
}

.icon-success { filter: invert(46%) sepia(56%) saturate(2637%) hue-rotate(88deg) brightness(97%) contrast(82%); }
.icon-dark { filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%) contrast(104%); }
.icon-danger { filter: invert(24%) sepia(83%) saturate(2719%) hue-rotate(343deg) brightness(92%) contrast(89%); }
.icon-secondary { filter: invert(46%) sepia(0%) saturate(1%) hue-rotate(167deg) brightness(94%) contrast(92%); }
.icon-primary { filter: invert(32%) sepia(98%) saturate(1098%) hue-rotate(187deg) brightness(96%) contrast(101%); }

.dropdown-item button:hover .icon-primary { filter: invert(23%) sepia(90%) saturate(1815%) hue-rotate(195deg) brightness(94%) contrast(101%); }
.dropdown-item button:hover .icon-secondary { filter: invert(38%) sepia(0%) saturate(0%) hue-rotate(202deg) brightness(94%) contrast(85%); }
.dropdown-item button:hover .icon-success { filter: invert(32%) sepia(49%) saturate(3380%) hue-rotate(93deg) brightness(95%) contrast(84%); }
.dropdown-item button:hover .icon-dark { filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(0%) contrast(100%); }
.dropdown-item button:hover .icon-danger { filter: invert(15%) sepia(90%) saturate(6449%) hue-rotate(356deg) brightness(77%) contrast(113%); }

/* Form Styles */
.form-switch .form-check-input {
  width: 3em;
}

.form-check-label {
  padding-left: 0.5em;
}

.form-range {
  width: 100px;
}

/* Media Queries */
@media (max-width: 422px) {
  .demo-text {
    font-size: 0.9rem;
  }
}

@media (max-width: 400px) {
  .navbar-container {
    padding: 0 20px;
  }
}








