html,
body {
  height: 100%;
}

body {
  background-color: #f5f5f5;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding-bottom: 200px;
}

.login-title {
  margin-bottom: 20px;
  color: #555; /* Change the color here */
}

.login-form {
  width: 360px;
  padding: 30px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.login-form .form-control {
  margin-bottom: 15px;
}

.login-form .btn {
  padding: 8px 16px;
  width: 100%;
}